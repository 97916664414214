import fiserveMerchant from './assets/arap/fiserv-merchants-logo.svg'
import rbcLogo from './assets/arap/rbc-logo-shield.svg'
import knitLogo from './assets/payroll/knit_logo.svg'
import caseware from './assets/papers/caseware-logo.svg'
import clienthib from './assets/workflow/clienthub.svg'
import ignition from './assets/workflow/ignition.svg'
import jetpack from './assets/workflow/jetpack.svg'
import salesforce from './assets/workflow/logo-salesforce.svg'
import wolters from './assets/workflow/wolters.svg'
import amazonseller from './assets/ecommerce/seller-central_logo.svg'
import clio from './assets/law/clio-logo.svg'

export const AUTOMATION_CATEGORY = [
    { name: 'ACCOUNTING & BOOKKEEPING', id: 1, country: 'CANADA' },
    { name: 'ACCOUNTING & BOOKKEEPING', id: 1, country: 'US' },
    { name: 'ACCOUNTING & BOOKKEEPING', id: 1, country: 'UK' },
    { name: 'ACCOUNTING & BOOKKEEPING', id: 1, country: 'AUS' },
    { name: 'DOCUMENT TRACKING & PUBLISHING', id: 2, country: 'CANADA' },
    { name: 'AR & AP SOLUTIONS', id: 3, country: 'CANADA' },
    { name: 'AR & AP SOLUTIONS', id: 3, country: 'US' },
    { name: 'AR & AP SOLUTIONS', id: 3, country: 'UK' },
    { name: 'AR & AP SOLUTIONS', id: 3, country: 'AUS' },
    { name: 'PAYROLL', id: 4, country: 'CANADA' },
    { name: 'PAYROLL', id: 4, country: 'US' },
    { name: 'PAYROLL', id: 4, country: 'UK' },
    { name: 'PAYROLL', id: 4, country: 'AUS' },
    { name: 'TAX PREP', id: 5, country: 'CANADA' },
    { name: 'TAX PREP', id: 5, country: 'US' },
    { name: 'TAX PREP', id: 5, country: 'UK' },
    { name: 'TAX PREP', id: 5, country: 'AUS' },
    { name: 'WORKING PAPERS', id: 6, country: 'CANADA' },
    { name: 'WORKING PAPERS', id: 6, country: 'US' },
    { name: 'WORKING PAPERS', id: 6, country: 'UK' },
    { name: 'WORKING PAPERS', id: 6, country: 'AUS' },
    { name: 'PERFORMANCE ANALYTICS (FRACTIONAL CFO)', id: 7, country: 'CANADA' },
    { name: 'PERFORMANCE ANALYTICS', id: 7, country: 'UK' },
    { name: 'PERFORMANCE ANALYTICS', id: 7, country: 'AUS' },
    { name: 'PRACTICE & WORKFLOW MANAGEMENT', id: 8, country: 'CANADA' },
    { name: 'PRACTICE & WORKFLOW MANAGEMENT', id: 8, country: 'US' },
    { name: 'PRACTICE & WORKFLOW MANAGEMENT', id: 8, country: 'UK' },
    { name: 'PRACTICE & WORKFLOW MANAGEMENT', id: 8, country: 'AUS' },
    { name: 'COMMUINICATION AND SECURITY', id: 9, country: 'CANADA' },
    { name: 'ERP', id: 10, country: 'CANADA' },
    { name: 'REAL ESTATE MANAGEMENT INDUSTRY', id: 11, country: 'CANADA' },
    { name: 'E-COMMERCE INDUSTRY', id: 12, country: 'CANADA' },
    { name: 'TECHNOLOGY INDUSTRY', id: 13, country: 'CANADA' },
    { name: 'HEALTHCARE INDUSTRY', id: 14, country: 'CANADA' },
    { name: 'CONSTRUCTION INDUSTRY', id: 15, country: 'CANADA' },
    { name: 'LAW INDUSTRY', id: 16, country: 'CANADA' },
    { name: 'EXPENSE TRACKING & PUBLISHING', id: 17, country: 'US' },
    { name: 'EXPENSE TRACKING & PUBLISHING', id: 17, country: 'UK' },
    { name: 'EXPENSE TRACKING & PUBLISHING', id: 17, country: 'AUS' },
    { name: 'INVENTORY', id: 18, country: 'US' },
    { name: 'INVENTORY', id: 18, country: 'UK' },
    { name: 'INVENTORY', id: 18, country: 'AUS' },
    { name: 'SYNC DATA', id: 19, country: 'US' },
    { name: 'SYNC DATA', id: 19, country: 'UK' },
    { name: 'SYNC DATA', id: 19, country: 'AUS' },
    { name: 'ONBOARDING', id: 20, country: 'US' },
]

export const AUTOMATION_SUB_CATEGORY = [
    { name: 'XERO', id: 1, categoryId: 1, img: require('./assets/accounting/zero.png'), country: 'CANADA' },
    { name: 'XERO', id: 1, categoryId: 1, img: require('./assets/accounting/zero.png'), country: 'US' },
    { name: 'XERO', id: 1, categoryId: 1, img: require('./assets/accounting/zero.png'), country: 'UK' },
    { name: 'XERO', id: 1, categoryId: 1, img: require('./assets/accounting/zero.png'), country: 'AUS' },
    { name: 'QBO', id: 2, categoryId: 1, img: require('./assets/accounting/quickbook.jpg'), country: 'CANADA' },
    { name: 'QBO', id: 2, categoryId: 1, img: require('./assets/accounting/quickbook.jpg'), country: 'US' },
    { name: 'Quickbooks Online', id: 2, categoryId: 1, img: require('./assets/accounting/quickbook.jpg'), country: 'UK' },
    { name: 'QBO', id: 2, categoryId: 1, img: require('./assets/accounting/quickbook.jpg'), country: 'AUS' },
    { name: 'Sage', id: 3, categoryId: 1, img: require('./assets/accounting/sage.jpg'), country: 'CANADA' },
    { name: 'Sage Desktop Version', id: 3, categoryId: 1, img: require('./assets/accounting/sage.jpg'), country: 'UK' },
    { name: 'Sage', id: 3, categoryId: 1, img: require('./assets/accounting/sage.jpg'), country: 'AUS' },
    { name: 'Wave', id: 4, categoryId: 1, img: require('./assets/accounting/wave.png'), country: 'CANADA' },
    { name: 'Spotlight', id: 5, categoryId: 1, img: require('./assets/accounting/spotlight.png'), country: 'CANADA' },
    { name: 'Quickbooks Desktop', id: 6, categoryId: 1, img: require('./assets/accounting/intuit_quick-books.png'), country: 'CANADA' },
    { name: 'Quickbooks Desktop', id: 6, categoryId: 1, img: require('./assets/accounting/intuit_quick-books.png'), country: 'US' },
    { name: 'Quickbooks Desktop', id: 6, categoryId: 1, img: require('./assets/accounting/intuit_quick-books.png'), country: 'AUS' },
    { name: 'Odoo', id: 7, categoryId: 1, img: require('./assets/accounting/odoo.png'), country: 'CANADA' },
    { name: 'Freshbooks', id: 8, categoryId: 1, img: require('./assets/accounting/freshbookspng.png'), country: 'CANADA' },
    { name: 'Lankar', id: 9, categoryId: 1, img: require('./assets/accounting/lankar-black.png'), country: 'CANADA' },
    { name: 'Zoho Books', id: 10, categoryId: 1, img: require('./assets/accounting/zoho-books-logo.webp'), country: 'CANADA' },
    { name: 'Zoho Books', id: 10, categoryId: 1, img: require('./assets/accounting/zoho-books-logo.webp'), country: 'US' },
    { name: 'Zoho Books', id: 10, categoryId: 1, img: require('./assets/accounting/zoho-books-logo.webp'), country: 'AUS' },
    { name: 'Excel', id: 102, categoryId: 1, img: require('./assets/papers/microsoft-excel-2013.jpg'), country: 'CANADA' },
    { name: 'Excel', id: 102, categoryId: 1, img: require('./assets/papers/microsoft-excel-2013.jpg'), country: 'UK' },
    { name: 'Excel', id: 102, categoryId: 1, img: require('./assets/papers/microsoft-excel-2013.jpg'), country: 'AUS' },
    { name: 'Clearbooks', id: 103, categoryId: 1, img: require('./assets/accounting/clear-books.jpg'), country: 'UK' },
    { name: 'Clearbooks', id: 103, categoryId: 1, img: require('./assets/accounting/clear-books.jpg'), country: 'AUS' },
    { name: 'Sage Cloud Version', id: 104, categoryId: 1, img: require('./assets/accounting/sage-business-cloud.png'), country: 'UK' },
    { name: 'AcuteBooks', id: 105, categoryId: 1, img: require('./assets/accounting/acutebooks.png'), country: 'UK' },
    { name: 'SAP Business One', id: 106, categoryId: 1, img: require('./assets/accounting/sap-business-one.png'), country: 'UK' },
    { name: 'SAP Business One', id: 106, categoryId: 1, img: require('./assets/accounting/sap-business-one.png'), country: 'AUS' },
    { name: 'Capium', id: 107, categoryId: 1, img: require('./assets/accounting/Capium.png'), country: 'UK' },
    { name: 'Boox', id: 108, categoryId: 1, img: require('./assets/accounting/boox.png'), country: 'UK' },
    { name: 'Concur', id: 109, categoryId: 1, img: require('./assets/accounting/Concur.png'), country: 'UK' },
    { name: 'CLIO', id: 110, categoryId: 1, img: clio, country: 'AUS' },
    { name: 'DEXT (New name of Receipt Bank)', id: 12, categoryId: 2, img: require('./assets/document/Dext-with-Receipt.jpeg'), country: 'CANADA' },
    { name: 'DEXT Precision (by Xavier)', id: 13, categoryId: 2, img: require('./assets/document/Dext-Xavier.jpg'), country: 'CANADA' },
    { name: 'DEXT Commerce (Greenback)', id: 14, categoryId: 2, img: require('./assets/document/Dext-Commerce-Greenback.png'), country: 'CANADA' },
    { name: 'Hubdoc', id: 15, categoryId: 2, img: require('./assets/document/hubdoc.png'), country: 'CANADA' },
    { name: 'QBO Receipts', id: 16, categoryId: 2, img: require('./assets/document/quickbook.jpg'), country: 'CANADA' },
    { name: 'Auto Entry', id: 17, categoryId: 2, img: require('./assets/document/auto-entry.jpg'), country: 'CANADA' },
    { name: 'Expensify', id: 18, categoryId: 2, img: require('./assets/document/Expensify.png'), country: 'CANADA' },
    { name: 'ApprovalMax', id: 19, categoryId: 2, img: require('./assets/document/approvalmax.webp'), country: 'CANADA' },
    { name: 'Saasant', id: 20, categoryId: 2, img: require('./assets/document/saasant.png'), country: 'CANADA' },
    { name: 'Plooto', id: 21, categoryId: 2, img: require('./assets/document/Plooto.png'), country: 'CANADA' },
    { name: 'LedgerDocs', id: 22, categoryId: 2, img: require('./assets/document/cropped-ledgerdocs-logo.webp'), country: 'CANADA' },
    { name: 'Paypal', id: 23, categoryId: 3, img: require('./assets/arap/paypal.png'), country: 'CANADA' },
    { name: 'Paypal', id: 23, categoryId: 3, img: require('./assets/arap/paypal.png'), country: 'US' },
    { name: 'Square', id: 24, categoryId: 3, img: require('./assets/arap/square.png'), country: 'CANADA' },
    { name: 'Plooto', id: 25, categoryId: 3, img: require('./assets/arap/Plooto.png'), country: 'CANADA' },
    { name: 'Plooto', id: 25, categoryId: 3, img: require('./assets/arap/Plooto.png'), country: 'AUS' },
    { name: 'Stripe', id: 26, categoryId: 3, img: require('./assets/arap/stripe.png'), country: 'CANADA' },
    { name: 'CIBC CMO', id: 27, categoryId: 3, img: require('./assets/arap/cibc.png'), country: 'CANADA' },
    { name: 'Bartecard', id: 28, categoryId: 3, img: require('./assets/arap/bartercard.png'), country: 'CANADA' },
    { name: 'Bill.com', id: 29, categoryId: 3, img: require('./assets/arap/bill-logo.png'), country: 'CANADA' },
    { name: 'Bill.com', id: 29, categoryId: 3, img: require('./assets/arap/bill-logo.png'), country: 'US' },
    { name: 'First Data', id: 30, categoryId: 3, img: fiserveMerchant, country: 'CANADA' },
    { name: 'RBC PayEdge', id: 31, categoryId: 3, img: rbcLogo, country: 'CANADA' },
    { name: 'Rotessa', id: 32, categoryId: 3, img: require('./assets/arap/rotessa.png'), country: 'CANADA' },
    { name: 'Bambora', id: 33, categoryId: 3, img: require('./assets/arap/bambora.png'), country: 'CANADA' },
    { name: 'BQE Core', id: 34, categoryId: 3, img: require('./assets/arap/bqe.png'), country: 'CANADA' },
    { name: 'Shopify', id: 89, categoryId: 3, img: require('./assets/ecommerce/shopify.png'), country: 'US' },
    { name: 'Amazon Seller', id: 94, categoryId: 3, img: amazonseller, country: 'US' },
    { name: 'ApprovalMax', id: 19, categoryId: 3, img: require('./assets/document/approvalmax.webp'), country: 'US' },
    { name: 'ServiceM8', id: 99, categoryId: 3, img: require('./assets/construction/ServiceM8_Logo_Horizontal.webp'), country: 'US' },
    { name: 'Sqaurespace', id: 111, categoryId: 3, img: require('./assets/arap/sqaurespace.png'), country: 'US' },
    { name: 'Greenback', id: 112, categoryId: 3, img: require('./assets/arap/greenback.png'), country: 'US' },
    { name: 'Goconstruct', id: 113, categoryId: 3, img: require('./assets/arap/construct.png'), country: 'US' },
    { name: 'NextGear capital', id: 114, categoryId: 3, img: require('./assets/arap/nextgear.png'), country: 'US' },
    { name: 'SAP Business One', id: 106, categoryId: 3, img: require('./assets/accounting/sap-business-one.png'), country: 'UK' },
    { name: 'BillPay', id: 115, categoryId: 3, img: require('./assets/arap/billpay.png'), country: 'AUS' },
    { name: 'Invoice Sherpa 2.0', id: 116, categoryId: 3, img: require('./assets/arap/invoice-sherpa.png'), country: 'AUS' },
    { name: 'Telpay', id: 117, categoryId: 3, img: require('./assets/arap/telplay.png'), country: 'AUS' },
    { name: 'Veem', id: 118, categoryId: 3, img: require('./assets/arap/veen.png'), country: 'AUS' },
    { name: 'WayPay', id: 119, categoryId: 3, img: require('./assets/arap/waypay.jpg'), country: 'AUS' },
    { name: 'Wagepoint', id: 35, categoryId: 4, img: require('./assets/payroll/wagepoint.png'), country: 'CANADA' },
    { name: 'Wagepoint', id: 35, categoryId: 4, img: require('./assets/payroll/wagepoint.png'), country: 'AUS' },
    { name: 'Payment Evolution', id: 36, categoryId: 4, img: require('./assets/payroll/parment-evolution.png'), country: 'CANADA' },
    { name: 'Payment Evolution', id: 36, categoryId: 4, img: require('./assets/payroll/parment-evolution.png'), country: 'AUS' },
    { name: 'ADP', id: 37, categoryId: 4, img: require('./assets/payroll/ADP.png'), country: 'CANADA' },
    { name: 'ADP', id: 37, categoryId: 4, img: require('./assets/payroll/ADP.png'), country: 'US' },
    { name: 'QBO Payroll', id: 38, categoryId: 4, img: require('./assets/payroll/quick-book.png'), country: 'CANADA' },
    { name: 'QBO Payroll', id: 38, categoryId: 4, img: require('./assets/payroll/quick-book.png'), country: 'US' },
    { name: 'QBO Payroll', id: 38, categoryId: 4, img: require('./assets/payroll/quick-book.png'), country: 'AUS' },
    { name: 'Quickbooks Desktop Payroll', id: 39, categoryId: 4, img: require('./assets/payroll/quick-book.png'), country: 'CANADA' },
    { name: 'Quickbooks Desktop Payroll', id: 39, categoryId: 4, img: require('./assets/payroll/quick-book.png'), country: 'US' },
    { name: 'Quickbooks Desktop Payroll', id: 39, categoryId: 4, img: require('./assets/payroll/quick-book.png'), country: 'AUS' },
    { name: 'Ceridian', id: 40, categoryId: 4, img: require('./assets/payroll/cerdian.png'), country: 'CANADA' },
    { name: 'Quickbooks Time (Tsheets)', id: 41, categoryId: 4, img: require('./assets/payroll/quickbooktime.jpg'), country: 'CANADA' },
    { name: 'Inclusion System', id: 42, categoryId: 4, img: require('./assets/payroll/inc_logo.png'), country: 'CANADA' },
    { name: 'Harvest', id: 43, categoryId: 4, img: require('./assets/payroll/Harvest-Logo.png'), country: 'CANADA' },
    { name: 'Payworks', id: 44, categoryId: 4, img: require('./assets/payroll/payworks.png'), country: 'CANADA' },
    { name: 'Sage Payroll', id: 45, categoryId: 4, img: require('./assets/payroll/sagepayroll.png'), country: 'CANADA' },
    { name: 'Knit', id: 46, categoryId: 4, img: knitLogo, country: 'CANADA' },
    { name: 'Xero Payroll', id: 47, categoryId: 4, img: require('./assets/payroll/xero-payroll.png'), country: 'CANADA' },
    { name: 'PDOC', id: 48, categoryId: 4, img: require('./assets/payroll/Flag_of_Canada_(Pantone).png'), country: 'CANADA' },
    { name: 'PDOC', id: 48, categoryId: 4, img: require('./assets/payroll/Flag_of_Canada_(Pantone).png'), country: 'CANADA' },
    { name: 'Gusto', id: 120, categoryId: 4, img: require('./assets/payroll/gusto.png'), country: 'US' },
    { name: 'Gusto', id: 120, categoryId: 4, img: require('./assets/payroll/gusto.png'), country: 'AUS' },
    { name: 'Paychex', id: 121, categoryId: 4, img: require('./assets/payroll/gusto.png'), country: 'US' },
    { name: 'Zoho Payroll', id: 122, categoryId: 4, img: require('./assets/payroll/zohopayroll.png'), country: 'US' },
    { name: 'Tsheets Time Tracking', id: 123, categoryId: 4, img: require('./assets/payroll/tsheet.png'), country: 'US' },
    { name: 'Tsheets Time Tracking', id: 123, categoryId: 4, img: require('./assets/payroll/tsheet.png'), country: 'AUS' },
    { name: 'Insperity', id: 124, categoryId: 4, img: require('./assets/payroll/insperity.png'), country: 'US' },
    { name: 'Moneysoft', id: 125, categoryId: 4, img: require('./assets/payroll/moneysoft.png'), country: 'UK' },
    { name: 'Moneysoft', id: 125, categoryId: 4, img: require('./assets/payroll/moneysoft.png'), country: 'AUS' },
    { name: 'SAP Business One', id: 106, categoryId: 4, img: require('./assets/accounting/sap-business-one.png'), country: 'UK' },
    { name: 'Capium', id: 126, categoryId: 4, img: require('./assets/payroll/Capium.png'), country: 'UK' },
    { name: 'Brightpay', id: 127, categoryId: 4, img: require('./assets/payroll/bright-pay.png'), country: 'AUS' },
    { name: 'TaxCycle', id: 49, categoryId: 5, img: require('./assets/tax/tax-cycle-logo-21.1658209089179.webp'), country: 'CANADA' },
    { name: 'TaxCycle', id: 49, categoryId: 5, img: require('./assets/tax/tax-cycle-logo-21.1658209089179.webp'), country: 'AUS' },
    { name: 'ProFile', id: 50, categoryId: 5, img: require('./assets/tax/profiletax.png'), country: 'CANADA' },
    { name: 'Protax', id: 51, categoryId: 5, img: require('./assets/tax/Protax.png'), country: 'CANADA' },
    { name: 'myTaxExpress', id: 52, categoryId: 5, img: require('./assets/tax/mtx02.jpg'), country: 'CANADA' },
    { name: 'Taxprep', id: 53, categoryId: 5, img: require('./assets/tax/Taxprep.jpg'), country: 'CANADA' },
    { name: 'Drake', id: 128, categoryId: 5, img: require('./assets/tax/drake.png'), country: 'US' },
    { name: 'IRIS', id: 129, categoryId: 5, img: require('./assets/tax/iris-tp.jpg'), country: 'US' },
    { name: 'IRIS', id: 129, categoryId: 5, img: require('./assets/tax/iris-tp.jpg'), country: 'UK' },
    { name: 'IRIS', id: 129, categoryId: 5, img: require('./assets/tax/iris-tp.jpg'), country: 'AUS' },
    { name: 'DavoSale Tax', id: 130, categoryId: 5, img: require('./assets/tax/davo.png'), country: 'US' },
    { name: 'Taxcalc', id: 131, categoryId: 5, img: require('./assets/tax/taxcalc-tp.jpg'), country: 'UK' },
    { name: 'Taxcalc', id: 131, categoryId: 5, img: require('./assets/tax/taxcalc-tp.jpg'), country: 'AUS' },
    { name: 'SAP Tax Digital', id: 106, categoryId: 5, img: require('./assets/accounting/sap-business-one.png'), country: 'UK' },
    { name: 'Capium', id: 126, categoryId: 5, img: require('./assets/payroll/Capium.png'), country: 'UK' },
    { name: 'Profile', id: 127, categoryId: 5, img: require('./assets/tax/profiletax.png'), country: 'AUS' },
    { name: 'T2 Express', id: 52, categoryId: 5, img: require('./assets/tax/mtx02.jpg'), country: 'AUS' },
    { name: 'Turbo Tax', id: 52, categoryId: 5, img: require('./assets/tax/turbotax-tp.jpg'), country: 'AUS' },
    { name: 'Caseware Desktop', id: 54, categoryId: 6, img: caseware, country: 'CANADA' },
    { name: 'Caseware', id: 54, categoryId: 6, img: caseware, country: 'AUS' },
    { name: 'Caseware Cloud', id: 55, categoryId: 6, img: caseware, country: 'CANADA' },
    { name: 'Caseware Cloud', id: 55, categoryId: 6, img: caseware, country: 'AUS' },
    { name: 'QuickBooks WorkPapers', id: 56, categoryId: 6, img: require('./assets/papers/QBO-Receipts.png'), country: 'CANADA' },
    { name: 'QBO Working Papers', id: 56, categoryId: 6, img: require('./assets/papers/QBO-Receipts.png'), country: 'AUS' },
    { name: 'Xero WorkPapers', id: 57, categoryId: 6, img: require('./assets/papers/xero-workpapers-logo.png'), country: 'CANADA' },
    { name: 'Xero Working papers', id: 57, categoryId: 6, img: require('./assets/papers/xero-workpapers-logo.png'), country: 'US' },
    { name: 'Xero Working papers', id: 57, categoryId: 6, img: require('./assets/papers/xero-workpapers-logo.png'), country: 'AUS' },
    { name: 'Excel', id: 58, categoryId: 6, img: require('./assets/papers/microsoft-excel-2013.jpg'), country: 'CANADA' },
    { name: 'Excel', id: 58, categoryId: 6, img: require('./assets/papers/microsoft-excel-2013.jpg'), country: 'US' },
    { name: 'Excel', id: 58, categoryId: 6, img: require('./assets/papers/microsoft-excel-2013.jpg'), country: 'UK' },
    { name: 'Excel', id: 58, categoryId: 6, img: require('./assets/papers/microsoft-excel-2013.jpg'), country: 'AUS' },
    { name: 'Countable', id: 58, categoryId: 6, img: require('./assets/papers/Countable-Logo-n1.png'), country: 'CANADA' },
    { name: 'Google Sheets', id: 132, categoryId: 6, img: require('./assets/papers/Countable-Logo-n1.png'), country: 'US' },
    { name: 'Capium', id: 126, categoryId: 6, img: require('./assets/payroll/Capium.png'), country: 'UK' },
    { name: 'CCH', id: 126, categoryId: 6, img: require('./assets/papers/hcc.jpg'), country: 'AUS' },
    { name: 'Fathom', id: 59, categoryId: 7, img: require('./assets/analytics/NLN_FathomLogo.png'), country: 'CANADA' },
    { name: 'Fathom', id: 59, categoryId: 7, img: require('./assets/analytics/NLN_FathomLogo.png'), country: 'UK' },
    { name: 'Fathom', id: 59, categoryId: 7, img: require('./assets/analytics/NLN_FathomLogo.png'), country: 'AUS' },
    { name: 'Spotlight', id: 60, categoryId: 7, img: require('./assets/analytics/spotlight.png'), country: 'CANADA' },
    { name: 'LivePlan', id: 61, categoryId: 7, img: require('./assets/analytics/liveplan.png'), country: 'CANADA' },
    { name: 'Syft', id: 62, categoryId: 7, img: require('./assets/analytics/syft.png'), country: 'CANADA' },
    { name: 'Syft Analytics', id: 62, categoryId: 7, img: require('./assets/analytics/syft.png'), country: 'UK' },
    { name: 'Syft Analytics', id: 62, categoryId: 7, img: require('./assets/analytics/syft.png'), country: 'AUS' },
    { name: 'Float', id: 133, categoryId: 7, img: require('./assets/analytics/float.png'), country: 'UK' },
    { name: 'Float', id: 133, categoryId: 7, img: require('./assets/analytics/float.png'), country: 'AUS' },
    { name: 'Futrli', id: 134, categoryId: 7, img: require('./assets/analytics/frutly.png'), country: 'UK' },
    { name: 'Futrli', id: 134, categoryId: 7, img: require('./assets/analytics/frutly.png'), country: 'AUS' },
    { name: 'VerifyIQ', id: 135, categoryId: 7, img: require('./assets/analytics/verify-1q.png'), country: 'UK' },
    { name: 'VerifyIQ', id: 135, categoryId: 7, img: require('./assets/analytics/verify-1q.png'), country: 'AUS' },
    { name: 'Karbon', id: 63, categoryId: 8, img: require('./assets/workflow/karbonn.png'), country: 'CANADA' },
    { name: 'Karbon', id: 63, categoryId: 8, img: require('./assets/workflow/karbonn.png'), country: 'AUS' },
    { name: 'Lastpass', id: 64, categoryId: 8, img: require('./assets/workflow/Lastpass-1.png'), country: 'CANADA' },
    { name: 'Asana', id: 136, categoryId: 8, img: require('./assets/workflow/asana.jpg'), country: 'CANADA' },
    { name: 'Asana', id: 136, categoryId: 8, img: require('./assets/workflow/asana.jpg'), country: 'AUS' },
    { name: 'ClickUp', id: 65, categoryId: 8, img: require('./assets/workflow/clickup.png'), country: 'CANADA' },
    { name: 'Box.com', id: 66, categoryId: 8, img: require('./assets/workflow/box-1.png'), country: 'CANADA' },
    { name: 'Financial Cents', id: 67, categoryId: 8, img: require('./assets/workflow/FC-inline.png'), country: 'CANADA' },
    { name: 'Practice Ignition', id: 68, categoryId: 8, img: ignition, country: 'CANADA' },
    { name: 'Practice Ignition', id: 68, categoryId: 8, img: ignition, country: 'AUS' },
    { name: 'Liscio', id: 69, categoryId: 8, img: require('./assets/workflow/liscio.webp'), country: 'CANADA' },
    { name: 'Jetpack Workflow', id: 70, categoryId: 8, img: jetpack, country: 'CANADA' },
    { name: 'Jetpack Workflow', id: 70, categoryId: 8, img: jetpack, country: 'AUS' },
    { name: 'CCH iFirm', id: 71, categoryId: 8, img: wolters, country: 'CANADA' },
    { name: 'Salesforce', id: 72, categoryId: 8, img: salesforce, country: 'CANADA' },
    { name: 'Senta Workflow', id: 73, categoryId: 8, img: require('./assets/workflow/Senta-By-IRIS-logo-lightgrey.png'), country: 'CANADA' },
    { name: 'Senta Workflow', id: 73, categoryId: 8, img: require('./assets/workflow/Senta-By-IRIS-logo-lightgrey.png'), country: 'AUS' },
    { name: 'Client Hub', id: 74, categoryId: 8, img: clienthib, country: 'CANADA' },
    { name: 'Hive', id: 75, categoryId: 8, img: require('./assets/workflow/hive_logo_icon_169032.png'), country: 'CANADA' },
    { name: 'Hive', id: 75, categoryId: 8, img: require('./assets/workflow/hive_logo_icon_169032.png'), country: 'US' },
    { name: 'TeamWork', id: 76, categoryId: 8, img: require('./assets/workflow/1200px-Teamwork-logo.png'), country: 'CANADA' },
    { name: 'TeamWork', id: 76, categoryId: 8, img: require('./assets/workflow/1200px-Teamwork-logo.png'), country: 'US' },
    { name: 'Dashlane', id: 77, categoryId: 8, img: require('./assets/workflow/dashlanewebp.webp'), country: 'CANADA' },
    { name: 'Keeper', id: 78, categoryId: 8, img: require('./assets/workflow/keeper-security9134.jpg'), country: 'CANADA' },
    { name: 'Zoho Books', id: 10, categoryId: 8, img: require('./assets/accounting/zoho-books-logo.webp'), country: 'US' },
    { name: 'Bill.com', id: 29, categoryId: 8, img: require('./assets/arap/bill-logo.png'), country: 'US' },
    { name: 'Zoom', id: 137, categoryId: 8, img: require('./assets/workflow/Zoom-Logo.png'), country: 'US' },
    { name: 'Iris', id: 138, categoryId: 8, img: require('./assets/workflow/iris1.png'), country: 'UK' },
    { name: 'Taxcalc', id: 131, categoryId: 8, img: require('./assets/tax/taxcalc-tp.jpg'), country: 'UK' },
    { name: 'Capium', id: 126, categoryId: 8, img: require('./assets/payroll/Capium.png'), country: 'UK' },
    { name: 'Excel', id: 58, categoryId: 8, img: require('./assets/papers/microsoft-excel-2013.jpg'), country: 'UK' },
    { name: 'Monday.com', id: 138, categoryId: 8, img: require('./assets/workflow/monday.png'), country: 'UK' },
    { name: 'Aero Workflow', id: 139, categoryId: 8, img: require('./assets/workflow/aero.png'), country: 'AUS' },
    { name: 'Trello', id: 140, categoryId: 8, img: require('./assets/workflow/trello.png'), country: 'AUS' },
    { name: 'Workflow Max', id: 141, categoryId: 8, img: require('./assets/workflow/WORKFLOW-MAX.jpg'), country: 'AUS' },
    { name: 'Teams', id: 79, categoryId: 9, img: require('./assets/security/teams.png'), country: 'CANADA' },
    { name: 'Slack', id: 80, categoryId: 9, img: require('./assets/security/slack.png'), country: 'CANADA' },
    { name: 'Google Suite', id: 81, categoryId: 9, img: require('./assets/security/g-suite.png'), country: 'CANADA' },
    { name: 'Missive', id: 82, categoryId: 9, img: require('./assets/security/missive.png'), country: 'CANADA' },
    { name: 'Microsoft Dynamics', id: 83, categoryId: 10, img: require('./assets/erp/Dynamics-365-Logo-2016.png'), country: 'CANADA' },
    { name: 'R12 Oracle', id: 84, categoryId: 10, img: require('./assets/erp/oracle.png'), country: 'CANADA' },
    { name: 'SAP', id: 85, categoryId: 10, img: require('./assets/erp/sap.png'), country: 'CANADA' },
    { name: 'NetSuite', id: 86, categoryId: 10, img: require('./assets/erp/NetSuite-Logo.png'), country: 'CANADA' },
    { name: 'Propertyware', id: 87, categoryId: 11, img: require('./assets/real-estate/logo-propertyware.png'), country: 'CANADA' },
    { name: 'Tenantpay', id: 88, categoryId: 11, img: require('./assets/real-estate/tenant.webp'), country: 'CANADA' },
    { name: 'Shopify', id: 89, categoryId: 12, img: require('./assets/ecommerce/shopify.png'), country: 'CANADA' },
    { name: 'WooCommerce', id: 90, categoryId: 12, img: require('./assets/ecommerce/Woocommerce.png') },
    { name: 'Lightspeed', id: 91, categoryId: 12, img: require('./assets/ecommerce/lightspeed-vector-logo.png'), country: 'CANADA' },
    { name: 'LFM', id: 92, categoryId: 12, img: require('./assets/ecommerce/LF-Marketplace-logo-horiz.png'), country: 'CANADA' },
    { name: 'A2X for Amazon', id: 93, categoryId: 12, img: require('./assets/ecommerce/a2x.png'), country: 'CANADA' },
    { name: 'Amazon Seller', id: 94, categoryId: 12, img: amazonseller, country: 'CANADA' },
    { name: 'ETSY', id: 95, categoryId: 12, img: require('./assets/ecommerce/etsy.jpg'), country: 'CANADA' },
    { name: 'Airtable', id: 96, categoryId: 13, img: require('./assets/tech/Airtable-Logo.png'), country: 'CANADA' },
    { name: 'Zapier', id: 96, categoryId: 13, img: require('./assets/tech/Zapier_logo.jpg'), country: 'CANADA' },
    { name: 'Jane App', id: 97, categoryId: 14, img: require('./assets/healthcare/jane.png'), country: 'CANADA' },
    { name: 'Jobber', id: 98, categoryId: 15, img: require('./assets/construction/jobber.png'), country: 'CANADA' },
    { name: 'ServiceM8', id: 99, categoryId: 15, img: require('./assets/construction/ServiceM8_Logo_Horizontal.webp'), country: 'CANADA' },
    { name: 'Knowify', id: 100, categoryId: 15, img: require('./assets/construction/knowify.png'), country: 'CANADA' },
    { name: 'CLIO', id: 101, categoryId: 16, img: clio, country: 'CANADA' },
    { name: 'Dext Prepare (RB)', id: 142, categoryId: 17, img: require('./assets/expense/Dext-Prepare-1.png'), country: 'US' },
    { name: 'Dext Prepare (RB)', id: 142, categoryId: 17, img: require('./assets/expense/Dext-Prepare-1.png'), country: 'UK' },
    { name: 'Hubdoc', id: 143, categoryId: 17, img: require('./assets/expense/hubdoc.png'), country: 'US' },
    { name: 'Hubdoc', id: 143, categoryId: 17, img: require('./assets/expense/hubdoc.png'), country: 'AUS' },
    { name: 'Hub.Checkout.com', id: 144, categoryId: 17, img: require('./assets/expense/checkout.png'), country: 'UK' },
    { name: 'ONVI', id: 145, categoryId: 17, img: require('./assets/expense/onvi.png'), country: 'UK' },
    { name: 'IZettle', id: 146, categoryId: 17, img: require('./assets/expense/izettle.png'), country: 'UK' },
    { name: 'EPOS', id: 147, categoryId: 17, img: require('./assets/expense/EPOS.png'), country: 'UK' },
    { name: 'Concur', id: 148, categoryId: 17, img: require('./assets/expense/Concur.png'), country: 'UK' },
    { name: 'Auto Entry', id: 17, categoryId: 17, img: require('./assets/document/auto-entry.jpg'), country: 'AUS' },
    { name: 'Expensify', id: 18, categoryId: 17, img: require('./assets/document/Expensify.png'), country: 'AUS' },
    { name: 'Receipt Bank', id: 149, categoryId: 17, img: require('./assets/expense/home.jpg'), country: 'AUS' },
    { name: 'TripLog', id: 150, categoryId: 17, img: require('./assets/expense/triple-logpng.png'), country: 'AUS' },
    { name: 'SAP Business One', id: 106, categoryId: 18, img: require('./assets/accounting/sap-business-one.png'), country: 'UK' },
    { name: 'Inventory - A2X', id: 93, categoryId: 18, img: require('./assets/ecommerce/a2x.png'), country: 'US' },
    { name: 'CIN 7', id: 151, categoryId: 18, img: require('./assets/inventory/sin-7.png'), country: 'AUS' },
    { name: 'DEAR Inventory', id: 152, categoryId: 18, img: require('./assets/inventory/dear-inventory.png'), country: 'AUS' },
    { name: 'A2X for Amazon', id: 93, categoryId: 19, img: require('./assets/ecommerce/a2x.png'), country: 'AUS' },
    { name: 'Auto Entry', id: 17, categoryId: 19, img: require('./assets/document/auto-entry.jpg'), country: 'AUS' },
    { name: 'Auto Entry', id: 17, categoryId: 19, img: require('./assets/document/auto-entry.jpg'), country: 'US' },
    { name: 'Auto Entry', id: 17, categoryId: 19, img: require('./assets/document/auto-entry.jpg'), country: 'UK' },
    { name: 'SAP Business One', id: 106, categoryId: 19, img: require('./assets/accounting/sap-business-one.png'), country: 'UK' },
    { name: 'Backupify', id: 153, categoryId: 19, img: require('./assets/syncdata/backify.png'), country: 'US' },
    { name: 'Zoho Drive', id: 154, categoryId: 19, img: require('./assets/syncdata/zohoworkdrive.png'), country: 'US' },
    { name: 'QBD Webconnect', id: 155, categoryId: 19, img: require('./assets/syncdata/webconnect.png'), country: 'US' },
    { name: 'Sync Dashboard', id: 156, categoryId: 19, img: require('./assets/syncdata/sync.png'), country: 'US' },
    { name: 'Shopify by OneSaas', id: 157, categoryId: 19, img: require('./assets/syncdata/one-saas.png'), country: 'AUS' },
    { name: 'Synder', id: 158, categoryId: 19, img: require('./assets/syncdata/snyder.png'), country: 'AUS' },
    { name: 'Practice Ignition', id: 68, categoryId: 20, img: ignition, country: 'US' },
]

export const EXPERTISE_LIST = [
    { title: 'Bookkeeping', content: 'Full cycle bookkeeping, bank reconciliation, bookkeeping “Clean Up” jobs, AR/AP handling, query reporting & handling & bookkeepers annual review.', country: 'CANADA', img: require('./assets/ic_bookkeeping.png') },
    { title: 'Bookkeeping', content: 'Full Cycle Bookkeeping Bank Reconciliation Bookkeeping “Clean Up” jobs AR/AP handling Query reporting & handling Bookkeepers annual review.', country: 'US', img: require('./assets/ic_bookkeeping.png') },
    { title: 'Bookkeeping', content: 'Full Cycle Bookkeeping Bank Reconciliation Bookkeeping “Clean Up” jobs AR/AP handling Query reporting & handling Bookkeepers annual review.', country: 'UK', img: require('./assets/ic_bookkeeping.png') },
    { title: 'Bookkeeping', content: 'Full Cycle Bookkeeping Bank Reconciliation Bookkeeping “Clean Up” jobs AR/AP handling Query reporting & handling Bookkeepers annual review.', country: 'AUS', img: require('./assets/ic_bookkeeping.png') },
    { title: 'HST Returns', content: 'Quarterly and annual HST review HST/GST return preparations.', country: 'CANADA', img: require('./assets/ic_hst_returns.png') },
    { title: 'Sales Tax Returns', content: 'Quarterly and Annual Sales Tax review Sales Tax return preparations.', country: 'US', img: require('./assets/ic_hst_returns.png') },
    { title: 'VAT Returns', content: 'Monthly/Quarterly VAT review and return preparations.', country: 'UK', img: require('./assets/ic_hst_returns.png') },
    { title: 'BAS', content: 'Quarterly and Annual BAS preparations/lodgement.', country: 'AUS', img: require('./assets/ic_hst_returns.png') },
    { title: 'Year End Accounts', content: 'Compilation (Notice to Reader) & Review Engagement support for CPA’s. Preparation of working papers, year-end accounts and T2 returns.', country: 'CANADA', img: require('./assets/ic_year_end_accounts.png') },
    { title: 'Year End Accounts', content: 'Reviewing Nominal ledger & Trial Balance Year-end accounts adjustments Reconciliations Capital gains calculation Working papers preparation.', country: 'US', img: require('./assets/ic_year_end_accounts.png') },
    { title: 'Year End Accounts', content: 'Reviewing Nominal ledger & Trial Balance Year-end accounts adjustments Reconciliations Capital gains calculation Working papers preparation.', country: 'UK', img: require('./assets/ic_year_end_accounts.png') },
    { title: 'Year End Accounts', content: 'Reviewing Nominal ledger & Trial Balance Year-end accounts adjustments Reconciliations Capital gains calculation Working papers preparation.', country: 'AUS', img: require('./assets/ic_year_end_accounts.png') },
    { title: 'Payroll', content: 'Payroll through cloud software generating payslips month and year end reporting employee data administration tax year end processing.', country: 'CANADA', img: require('./assets/ic_payroll.png') },
    { title: 'Payroll', content: 'Payroll through cloud software Generating payslips Month and year end reporting Employee data administration Tax year end processing.', country: 'US', img: require('./assets/ic_payroll.png') },
    { title: 'Payroll', content: 'Payroll through cloud software Generating payslips Month and year end reporting Employee data administration Tax year end processing.', country: 'UK', img: require('./assets/ic_payroll.png') },
    { title: 'Payroll', content: 'Payroll through cloud software Generating payslips Month and year end reporting Employee data administration Tax year end processing.', country: 'AUS', img: require('./assets/ic_payroll.png') },
    { title: 'Management Accounts', content: 'Custom management reports, KPI reports, MIS reports, budgets preparation, cashflow reports & advisory reports.', country: 'CANADA', img: require('./assets/ic_Management-Accounts-icon.png') },
    { title: 'Management Accounts', content: 'Custom Management Reports KPI reports MIS Reports Budgets preparation Cashflow reports Advisory Reports.', country: 'US', img: require('./assets/ic_Management-Accounts-icon.png') },
    { title: 'Management Accounts', content: 'Custom Management Reports KPI reports MIS Reports Budgets preparation Cashflow reports Advisory Reports.', country: 'UK', img: require('./assets/ic_Management-Accounts-icon.png') },
    { title: 'Management Accounts', content: 'Custom Management Reports KPI reports MIS Reports Budgets preparation Cashflow reports Advisory Reports.', country: 'AUS', img: require('./assets/ic_Management-Accounts-icon.png') },
    { title: 'Specialised Consulting Services', content: 'Transforming finance functions of an organisation.', country: 'CANADA', img: require('./assets/ic_SCS.png') },
    { title: 'Specialised Consulting Services', content: 'Transforming finance functions of an organisation.', country: 'US', img: require('./assets/ic_SCS.png') },
    { title: 'Specialised Consulting Services', content: 'Transforming finance functions of an organisation.', country: 'UK', img: require('./assets/ic_SCS.png') },
    { title: 'Specialised Consulting Services', content: 'Transforming finance functions of an organisation.', country: 'AUS', img: require('./assets/ic_SCS.png') },
]

export const SKILLS_LIST = [
    { skill: 'Cloud & Desktop Bookkeeping', juniorLevel: true, midLevel: true, seniorLevel: true, country: 'CANADA' },
    { skill: 'Cloud & Desktop Bookkeeping', juniorLevel: true, midLevel: true, seniorLevel: true, country: 'US' },
    { skill: 'Cloud & Desktop Bookkeeping', juniorLevel: true, midLevel: true, seniorLevel: true, country: 'UK' },
    { skill: 'Cloud & Desktop Bookkeeping', juniorLevel: true, midLevel: true, seniorLevel: true, country: 'AUS' },
    { skill: 'Bank Reconciliation', juniorLevel: true, midLevel: true, seniorLevel: true, country: 'CANADA' },
    { skill: 'Bank Reconciliation', juniorLevel: true, midLevel: true, seniorLevel: true, country: 'US' },
    { skill: 'Bank Reconciliation', juniorLevel: true, midLevel: true, seniorLevel: true, country: 'UK' },
    { skill: 'Bank Reconciliation', juniorLevel: true, midLevel: true, seniorLevel: true, country: 'AUS' },
    { skill: 'Payroll Processing', juniorLevel: true, midLevel: true, seniorLevel: true, country: 'CANADA' },
    { skill: 'Payroll Processing', juniorLevel: true, midLevel: true, seniorLevel: true, country: 'US' },
    { skill: 'Payroll Processing', juniorLevel: true, midLevel: true, seniorLevel: true, country: 'UK' },
    { skill: 'Payroll Processing', juniorLevel: true, midLevel: true, seniorLevel: true, country: 'AUS' },
    { skill: 'Year-End Bookkeepers Review', juniorLevel: false, midLevel: true, seniorLevel: true, country: 'CANADA' },
    { skill: 'Year-End Bookkeepers Review', juniorLevel: false, midLevel: true, seniorLevel: true, country: 'US' },
    { skill: 'Year-End Bookkeepers Review', juniorLevel: false, midLevel: true, seniorLevel: true, country: 'UK' },
    { skill: 'Year-End Bookkeepers Review', juniorLevel: false, midLevel: true, seniorLevel: true, country: 'AUS' },
    { skill: 'HST/GST Tax Return Preparation', juniorLevel: false, midLevel: true, seniorLevel: true, country: 'CANADA' },
    { skill: 'Sales Tax Return Preparation', juniorLevel: false, midLevel: true, seniorLevel: true, country: 'US' },
    { skill: 'VAT Return Preparation', juniorLevel: false, midLevel: true, seniorLevel: true, country: 'UK' },
    { skill: 'BAS Return Preparation', juniorLevel: false, midLevel: true, seniorLevel: true, country: 'AUS' },
    { skill: 'Management Accounts', juniorLevel: false, midLevel: true, seniorLevel: true, country: 'CANADA' },
    { skill: 'Management Accounts', juniorLevel: false, midLevel: true, seniorLevel: true, country: 'US' },
    { skill: 'Management Accounts', juniorLevel: false, midLevel: true, seniorLevel: true, country: 'UK' },
    { skill: 'Management Accounts', juniorLevel: false, midLevel: true, seniorLevel: true, country: 'AUS' },
    { skill: 'Clean up Bookkeeping jobs', juniorLevel: false, midLevel: true, seniorLevel: true, country: 'CANADA' },
    { skill: 'Clean up Bookkeeping jobs', juniorLevel: false, midLevel: true, seniorLevel: true, country: 'US' },
    { skill: 'Clean up Bookkeeping jobs', juniorLevel: false, midLevel: true, seniorLevel: true, country: 'UK' },
    { skill: 'Clean up Bookkeeping jobs', juniorLevel: false, midLevel: true, seniorLevel: true, country: 'AUS' },
    { skill: 'Working Papers & Year End Accounts', juniorLevel: false, midLevel: false, seniorLevel: true, country: 'CANADA' },
    { skill: 'Compilation & Review Engagements Support', juniorLevel: false, midLevel: false, seniorLevel: true, country: 'CANADA' },
    { skill: 'Preparation of T2 Returns', juniorLevel: false, midLevel: false, seniorLevel: true, country: 'CANADA' },
    { skill: 'Year End Accounts Preparation', juniorLevel: false, midLevel: false, seniorLevel: true, country: 'US' },
    { skill: 'Year End Accounts Preparation', juniorLevel: false, midLevel: false, seniorLevel: true, country: 'UK' },
    { skill: 'Year End Accounts Preparation', juniorLevel: false, midLevel: false, seniorLevel: true, country: 'AUS' },
    { skill: 'Working Papers Preparation', juniorLevel: false, midLevel: false, seniorLevel: true, country: 'US' },
    { skill: 'Working Papers Preparation', juniorLevel: false, midLevel: false, seniorLevel: true, country: 'UK' },
    { skill: 'Working Papers Preparation', juniorLevel: false, midLevel: false, seniorLevel: true, country: 'AUS' },
    { skill: 'Specialised Consulting', juniorLevel: false, midLevel: false, seniorLevel: true, country: 'CANADA' },
    { skill: 'Specialised Consulting', juniorLevel: false, midLevel: false, seniorLevel: true, country: 'US' },
    { skill: 'Specialised Consulting', juniorLevel: false, midLevel: false, seniorLevel: true, country: 'UK' },
    { skill: 'Specialised Consulting', juniorLevel: false, midLevel: false, seniorLevel: true, country: 'AUS' },
]

export const PRICING_PLAN = [
    {
        name: 'Full Time Virtual Employee Services Plan', currencyLabel: 'CAD $', amount: '12.50', duration: 'PER HOUR', country: 'CANADA',
        features: [
            'One or more dedicated virtual employee/s',
            '40 hours a week per virtual employee',
            'Pay monthly at completion of month',
            '14 annual paid leaves per virtual employee',
            'Direct communication and management',
            'Select from 3 experience levels: junior, mid-level & senior'
        ]
    },
    {
        name: 'Capped Fixed Hours Services Plan', currencyLabel: 'CAD $', amount: '15.00', duration: 'PER HOUR', country: 'CANADA',
        features: [
            'One part time designated virtual employee',
            '10 + hours a week(fixed monthly cap)',
            'Pay monthly at completion of month',
            '14 annual unpaid leaves – no backup resources',
            'Direct communication and management',
            'Select from 3 experience levels: junior, mid - level & senior'
        ]
    },
    {
        name: 'PM (Practice Manager) Managed Services Plan', currencyLabel: 'CAD $', amount: '17.50', duration: 'PER HOUR', country: 'CANADA',
        features: [
            '10-200 hours per week with a designated virtual team lead by a PM',
            'Option between full time or fixed hours',
            'Pay monthly at completion of month',
            'No annual paid or unpaid leaves – backup from within the team',
            'Communication and management lead by a practice manager',
            'All experience levels inclusive in one service deployment'
        ]
    },
    {
        name: 'Full Time Employee Plan', currencyLabel: 'US $', amount: '7.8', duration: 'PER HOUR', country: 'US',
        features: [
            '40 hours a week',
            'Pay monthly at completion of month',
            '20 Full Annual Leaves',
            'Dedicated Virtual Employee',
            'Direct Communication and Management',
            '3 experience levels: Junior, Mid-Level & Senior'
        ]
    },
    {
        name: 'Fixed Hours Plan', currencyLabel: 'US $', amount: '9.0', duration: 'PER HOUR', country: 'US',
        features: [
            '10 + hours a week',
            'Pay monthly at completion of month',
            'No Annual Leaves',
            'Dedicated Virtual Employee',
            'Direct Communication and Management',
            '3 experience levels: Junior, Mid - Level & Senior',
        ]
    },
    {
        name: 'Ad Hoc Hours Plan', currencyLabel: 'US $', amount: '16.00', duration: 'PER HOUR', country: 'US',
        features: [
            'No Fixed commitment of hours',
            'Pay as you go',
            'No Annual Leaves',
            'No Dedicated Resource',
            'Direct Communication and Management',
            '2 experience levels: Junior & Senior'
        ]
    },
    {
        name: 'Full Time Employee Plan', currencyLabel: 'UK £', amount: '6.25', duration: 'PER HOUR', country: 'UK',
        features: [
            '40 hours a week',
            'Pay monthly at completion of month',
            '20 Full Annual Leaves',
            'Dedicated Virtual Employee',
            'Direct Communication and Management',
            '3 experience levels: Junior, Mid-Level & Senior'
        ]
    },
    {
        name: 'Fixed Hours Plan', currencyLabel: 'UK £', amount: '7.5', duration: 'PER HOUR', country: 'UK',
        features: [
            '10 + hours a week',
            'Pay monthly at completion of month',
            'No Annual Leaves',
            'Dedicated Virtual Employee',
            'Direct Communication and Management',
            '3 experience levels: Junior, Mid - Level & Senior',
        ]
    },
    {
        name: 'Ad Hoc Hours Plan', currencyLabel: 'UK £', amount: '10.00', duration: 'PER HOUR', country: 'UK',
        features: [
            'No Fixed commitment of hours',
            'Pay as you go',
            'No Annual Leaves',
            'No Dedicated Resource',
            'Direct Communication and Management',
            '2 experience levels: Junior & Senior'
        ]
    },
    {
        name: 'Full Time Employee Plan', currencyLabel: 'AUD $', amount: '11.00', duration: 'PER HOUR', country: 'AUS',
        features: [
            '40 hours a week',
            'Pay monthly at completion of month',
            '20 Full Annual Leaves',
            'Dedicated Virtual Employee',
            'Direct Communication and Management',
            '3 experience levels: Junior, Mid-Level & Senior'
        ]
    },
    {
        name: 'Fixed Hours Plan', currencyLabel: 'AUD $', amount: '13.50', duration: 'PER HOUR', country: 'AUS',
        features: [
            '10 + hours a week',
            'Pay monthly at completion of month',
            'No Annual Leaves',
            'Dedicated Virtual Employee',
            'Direct Communication and Management',
            '3 experience levels: Junior, Mid - Level & Senior',
        ]
    },
    {
        name: 'Ad Hoc Hours Plan', currencyLabel: 'AUD $', amount: '16.00', duration: 'PER HOUR', country: 'AUS',
        features: [
            'No Fixed commitment of hours',
            'Pay as you go',
            'No Annual Leaves',
            'No Dedicated Resource',
            'Direct Communication and Management',
            '2 experience levels: Junior & Senior'
        ]
    },
]